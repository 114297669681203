import { Component } from '@angular/core'
import { MatTabsModule } from '@angular/material/tabs'

import { FormControl } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { ActivatedRoute, Router } from '@angular/router'
import { DatePickerRangeComponent } from '../../../components/date-picker-range/date-picker-range'
import { ModalDownloadFileComponent } from '../../../components/modal-download-file/modal-download-file'
import { SelectComponent } from '../../../components/select/select'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { TextTooltipComponent } from '../../../components/text-tooltip/text-tooltip'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import {
  COMPANY_ID,
  DownloadFileBlob,
  Moment,
  channelIcon,
  convertDateToApi,
  formatPrice,
  formatPriceAutoDecimal,
} from '../../../helpers'
import { imports } from '../../../imports'
import { ReportService } from '../../../services'
import { ChartComponent } from './chart/chart'
@Component({
  selector: 'app-report-sales',
  standalone: true,
  imports: [
    ...imports,
    MatTabsModule,
    MatPaginatorModule,
    MatTooltipModule,
    DatePickerRangeComponent,
    SelectComponent,
    MatProgressBarModule,
    ChartComponent,
    TextTooltipComponent,
  ],
  templateUrl: './sales.html',
  styleUrls: ['./sales.scss'],
})
export class ReportSalesComponent {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  // public options: AgChartOptions
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly channelIcon = channelIcon as any

  year = new FormControl<number | null>(Moment().year())
  month = new FormControl<number | null>(null)

  start_date = new FormControl(null)
  end_date = new FormControl(null)

  minDate: any
  maxDate: any

  months = [
    { value: 1, text: 'มกราคม', short: 'ม.ค.', key: 'jan' },
    { value: 2, text: 'กุมพาพันธ์', short: 'ก.พ.', key: 'feb' },
    { value: 3, text: 'มีนาคม', short: 'มี.ค.', key: 'mar' },
    { value: 4, text: 'เมษายน', short: 'เม.ย.', key: 'apr' },
    { value: 5, text: 'พฤษภาคม', short: 'พ.ค.', key: 'may' },
    { value: 6, text: 'มิถุนายน', short: 'มิ.ย.', key: 'jun' },
    { value: 7, text: 'กรกฎาคม', short: 'ก.ค.', key: 'jul' },
    { value: 8, text: 'สิงหาคม', short: 'ส.ค.', key: 'aug' },
    { value: 9, text: 'กันยายน', short: 'ก.ย.', key: 'sep' },
    { value: 10, text: 'ตุลาคม', short: 'ต.ค.', key: 'oct' },
    { value: 11, text: 'พฤศจิกายน', short: 'พ.ย.', key: 'nov' },
    { value: 12, text: 'ธันวาคม', short: 'ธ.ค.', key: 'dec' },
  ]
  list = {
    year: <any[]>[],
    month: <any[]>[{ value: 0, text: 'ทั้งหมด' }, ...this.months],
  }
  minWidthChart = 800
  stepSize = 10
  minChart = 0
  maxChart = 200
  minAbs = 0
  maxAbs = 0
  trueMax = 0
  dataChart: any = []
  dataChartColor: any = []
  dataChartCategories: any[] = []

  orders = {
    columns: <any[]>[],
    total: 0,
  }

  summary = {
    stock: 0,
    stock_cost: 0,
    stock_price: 0,
    total_day_sale_price: 0,
    total_month_sale_price: 0,
    total_sale_price: 0,
    total_margin: 0,
  }

  tableList = {
    channels: <any[]>[],
    expenses: <any[]>[],
  }

  get themeColor() {
    if (this.profile.companyId == COMPANY_ID.PANIA) {
      return {
        main: '#B7717B',
        light: '#FCE9E7',
      }
    } else {
      return {
        main: '#1A9FD6',
        light: '#A4DCF3',
      }
    }
  }

  get monthSelect() {
    return this.months.find((d: any) => d.value == this.month.value)
  }

  // get dayOfMonth() {
  //   return this.monthSelect ? new Array(Moment().daysInMonth()) : []
  // }

  get isMonth() {
    return !!this.monthSelect
  }

  get columns() {
    return this.tableList.channels[0]?.columns || []
  }

  constructor(
    public route: ActivatedRoute,
    public Router: Router,
    public dialog: MatDialog,
    public profile: Profile,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public reportService: ReportService
  ) {
    this.dataChart = this.setChartNew()
  }

  ngOnInit(): void {
    const years: any[] = []
    let start_year = 2024
    const end_year = Moment().year()
    while (start_year <= end_year) {
      years.push({
        value: start_year,
        text: start_year,
      })
      start_year++
    }
    this.list.year = years

    this.year.valueChanges.subscribe(() => {
      this.month.reset(0)
      this.start_date.reset()
      this.end_date.reset()
    })

    this.month.valueChanges.subscribe(value => {
      if (value) {
        const date = Moment()
          .set('year', this.year.value as number)
          .set('month', value - 1)
          .startOf('month')
        this.minDate = date.format()
        this.maxDate = date.endOf('month').format()
      }
      this.start_date.reset()
      this.end_date.reset()

      this.getSaleReport()
    })

    this.year.setValue(end_year)
  }

  getSaleReport() {
    const payload: any = {
      year: this.year.value,
      month: this.month.value || null,
      start_date: null,
      end_date: null,
    }

    const daySelects: string[] = []
    let startDay = 1
    let endDay = 31
    if (payload.month) {
      payload.month = this.month.value
      endDay = Moment(this.month.value).endOf('month').date()

      let start_date = Moment()
      if (this.start_date.value) {
        start_date = Moment(this.start_date.value)
        startDay = start_date.date()

        payload.start_date = convertDateToApi(start_date)

        if (this.end_date.value) {
          const end_date = Moment(this.end_date.value)
          endDay = end_date.date()
          payload.end_date = convertDateToApi(this.end_date.value, 'day')
        } else {
          payload.end_date = convertDateToApi(start_date, 'month')
        }
      }

      if (startDay > 0 && endDay > 0) {
        for (let index = startDay; index <= endDay; index++) {
          daySelects.push(`day` + `0${index}`.slice(-2))
        }
      }
    }

    this.dataChart = []
    const api = payload.month
      ? this.reportService.getReportSaleMonth(payload)
      : this.reportService.getReportSaleYear(payload)
    this.loading.start()
    api.subscribe(res => {
      // res = mock
      if (!res.is_error) {
        this.summary = res.data.summary

        const dayInMonth = this.isMonth
          ? Moment()
              .set('month', payload.month - 1)
              .daysInMonth()
          : 0

        const order_columns: any[] = []
        this.setItem(dayInMonth, order_columns, res.data.orders, daySelects)

        this.orders.columns = order_columns
        this.orders.total = res.data.orders?.total || 0

        this.tableList.channels = res.data.channels.map((item: any) => {
          const columns: any[] = []
          this.setItem(dayInMonth, columns, item, daySelects)
          item.columns = columns
          return item
        })
        this.tableList.expenses = res.data.expenses.map((item: any) => {
          const columns: any[] = []
          this.setItem(dayInMonth, columns, item, daySelects)
          item.columns = columns
          return item
        })

        if (dayInMonth) {
          this.minWidthChart = daySelects.length * 60
        } else {
          this.minWidthChart = 800
        }
        this.dataChart = this.setChartNew()
      } else {
        this.customSnackBar.fail()
      }

      this.loading.stop()
    })
  }

  setItem(dayInMonth: number, columns: any[], item: any, daySelects: string[]) {
    if (this.isMonth) {
      for (let i = 1; i <= dayInMonth; i++) {
        const day = `0${i}`.slice(-2)
        const col = `day${day}`
        if (daySelects.length == 0 || daySelects.includes(col)) {
          const value = item[col]
          if (value !== undefined) {
            columns.push({
              name: `${i} ${this.monthSelect?.short}`,
              value,
            })
          }
        }
      }
    } else {
      for (const m of this.months) {
        const value = item[m.key]
        if (value !== undefined) {
          columns.push({
            name: m.short,
            value,
          })
        }
      }
    }

    item.percent = (item.total * 100) / this.summary.total_sale_price
  }

  setChartNew() {
    const orderTotal = this.orders
    const channelTotal = this.tableList.channels.find((d: any) => d.is_total)
    const expenseTotal = this.tableList.expenses.find((d: any) => d.is_total)
    const data: any[] = []

    this.minChart = 0
    this.maxChart = 10
    if (channelTotal) {
      channelTotal.columns.map((col: any, i: number) => {
        const orderValue = orderTotal.columns[i].value
        const profit = expenseTotal.columns[i].value
        data.push({
          quarter: col.name,
          order: orderValue.toFixed(2),
          total_sales: col.value.toFixed(2),
          profit: profit.toFixed(2),
        })
        //min
        if (this.minChart > orderValue) {
          this.minChart = orderValue
        }
        if (this.minChart > col.value) {
          this.minChart = col.value
        }
        if (this.minChart > profit) {
          this.minChart = profit
        }
        //max
        if (this.maxChart < orderValue) {
          this.maxChart = orderValue
        }
        if (this.maxChart < col.value) {
          this.maxChart = col.value
        }
        if (this.maxChart < profit) {
          this.maxChart = profit
        }
      })
    }

    const minAbs = Math.abs(this.minChart)
    const maxAbs = Math.abs(this.maxChart)
    this.minAbs = minAbs
    this.maxAbs = maxAbs
    this.trueMax = Math.max(minAbs, maxAbs)
    // stepMax.replace(0, 1)
    // console.log('stepMax', this.trueMax)

    let total_order = data?.map((item: any) => {
      return item.order
    })
    let total_sales = data?.map((item: any) => {
      return item.total_sales
    })
    let profit = data?.map((item: any) => {
      return item.profit
    })
    let categories =
      channelTotal?.columns?.map((item: any) => {
        return item.name
      }) || []

    this.dataChartColor = ['#FB923C', this.themeColor.main, this.themeColor.light]
    let dataChart = [
      {
        name: 'ยอดออเดอร์ทั้งหมด',
        data: total_order,
      },
      {
        name: 'ยอดขายรวม (ปิดรายการแล้ว)',
        data: total_sales,
      },
      {
        name: 'กำไร',
        data: profit,
      },
    ]

    this.dataChartCategories = categories

    return dataChart
  }

  openModalDownloadFile(title: string, detail: any) {
    const dialogRef = this.dialog.open(ModalDownloadFileComponent, {
      data: {
        title,
        detail,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.downloadFile()
      }
    })

    return dialogRef
  }

  downloadFile() {
    const payload: any = {
      year: this.year.value,
      month: this.month.value || null,
      start_date: null,
      end_date: null,
    }

    if (this.start_date.value) {
      payload.start_date = convertDateToApi(this.start_date.value)

      if (this.end_date.value) {
        payload.end_date = convertDateToApi(this.end_date.value, 'day')
      }
    }

    const api = payload.month
      ? this.reportService.exportReportSaleMonth(payload)
      : this.reportService.exportReportSaleYear(payload)

    this.loading.start()
    api.subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          DownloadFileBlob(res.body, `report-sale-${payload.month ? 'month' : 'year'}.xlsx`)
          this.customSnackBar.success('ดาวน์โหลดข้อมูลสำเร็จ')
        } else {
          this.customSnackBar.fail('ดาวน์โหลดข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }
}
