import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatDialog } from '@angular/material/dialog'
import { ReactiveFormsModule } from '@angular/forms'

import { FilterTableProduct, Loading } from '../../../globals'
import { RoleService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { InputSearchComponent } from '../../../components/input-search/input-search'
import { imports } from '../../../imports'
import { ModalFilterProductComponent } from '../modal-filter-product/modal-filter-product'
import { status_ids_list, status_product_ids_list, status_stock_ids_list } from '../filter-data'
import { ModalFilterWishListComponent } from '../modal-filter-wish-list/modal-filter-wish-list'

@Component({
  selector: 'app-search-filter-product',
  standalone: true,
  imports: [
    ...imports,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    ReactiveFormsModule,
    ModalFilterProductComponent,
    InputSearchComponent,
  ],
  templateUrl: './search-filter.html',
  styleUrls: ['./search-filter.scss'],
})
export class SearchFilterComponent {
  @Input() placeholder = ''
  @Input() tab = 0
  @Output() onConfirm = new EventEmitter()

  readonly status_product_ids_list = status_product_ids_list
  readonly status_stock_ids_list = status_stock_ids_list
  readonly status_ids_list = status_ids_list

  constructor(
    public dialog: MatDialog,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public filterTableProduct: FilterTableProduct
  ) {}

  ngOnInit(): void {
    this.filterTableProduct.initData()
  }

  async openModalFilter() {
    const dialogRef = this.dialog.open(ModalFilterProductComponent, {
      data: {
        title: 'ตัวกรอง',
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onConfirm.emit()
      } else {
        this.filterTableProduct.close()
      }
    })
  }

  async openModalWishListFilter() {
    const dialogRef = this.dialog.open(ModalFilterWishListComponent, {
      data: {
        title: 'ตัวกรอง',
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onConfirm.emit()
      } else {
        this.filterTableProduct.close()
      }
    })
  }


  afterFilter() {
    this.filterTableProduct.confirm()
    this.onConfirm.emit()
  }

  getName(id: any, list: any) {
    return list.find((item: any) => item.id === id)
  }
}
