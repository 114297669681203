import { Component } from '@angular/core'
import { imports } from '../../../../imports'
import { MatDialog } from '@angular/material/dialog'
import { FilterTableOrder, Loading } from '../../../../globals'
import { ModalSlipComponent } from './modal-slip/modal-slip'
import { OrderService } from '../../../../services'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { ActivatedRoute } from '@angular/router'
import { forkJoin } from 'rxjs'
import { Moment, ORDER_ID } from '../../../../helpers'
import { showCustomerName } from '../../../../helpers/customer'

@Component({
  selector: 'app-history-order-logs',
  standalone: true,
  imports: [...imports],
  templateUrl: './history-order-logs.html',
  styleUrls: ['./history-order-logs.scss'],
})
export class HistoryOrderLogsComponent {
  readonly Moment = Moment
  readonly ORDER_ID = ORDER_ID
  readonly showCustomerName = showCustomerName

  order_id: any
  order: any
  logList: any = []

  constructor(
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.getList((this.order_id = params['id']))
    })
  }

  getList(id: any): void {
    const apis = [this.orderService.getOrder(id), this.orderService.getLogs(id)]

    this.loading.start()
    forkJoin(apis).subscribe(([resOrder, resLog]: any) => {
      if (resOrder) {
        if (!resOrder.is_error) {
          this.order = resOrder.data
        } else {
          this.customSnackBar.fail()
        }
      }

      if (resLog) {
        if (!resLog.is_error) {
          this.logList = resLog.data
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  openModalSlip(title: string, item: any) {
    const dialogRef = this.dialog.open(ModalSlipComponent, {
      data: {
        title,
        item,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    })

    return dialogRef
  }
}
