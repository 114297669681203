import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private service: Service) {}
  private pathProduct = '/v1/products'

  getProductList(payload: any): Observable<any> {
    return this.service.post(`${this.pathProduct}/query`, payload)
  }
  getProduct(id: number): Observable<any> {
    return this.service.get(`${this.pathProduct}/${id}`)
  }
  addProduct(payload: any): Observable<any> {
    return this.service.post(`${this.pathProduct}`, payload)
  }
  updateProduct(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathProduct}/${id}`, payload)
  }
  deleteProduct(id: number, payload: any = {}): Observable<any> {
    return this.service.delete(`${this.pathProduct}/${id}`, payload)
  }
  deleteProductBulk(payload: any = {}): Observable<any> {
    return this.service.post(`${this.pathProduct}/bulk-delete`, payload)
  }
  checkImportProduct(payload: any): Observable<any> {
    return this.service.post(`${this.pathProduct}/pre-import`, payload)
  }
  importProduct(payload: any): Observable<any> {
    return this.service.post(`${this.pathProduct}/import`, payload)
  }
  exportProduct(payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathProduct}/export`, payload)
  }
  templateProduct(payload: any): Observable<any> {
    return this.service.getBlob(`${this.pathProduct}/template`, payload)
  }

  getProductOrderList(id: any, payload: any): Observable<any> {
    return this.service.post(`${this.pathProduct}/${id}/orders`, payload)
  }
  exportProductOrder(id: any, payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathProduct}/${id}/orders/export`, payload)
  }
  getProductForOrderList(payload: any): Observable<any> {
    return this.service.post(`${this.pathProduct}/order/query`, payload)
  }
  exportProductOrderReturn(id: any, payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathProduct}/${id}/order-return-histories/export`, payload)
  }

  getProductPromotionList(payload: any): Observable<any> {
    return this.service.post(`${this.pathProduct}/promotion/query`, payload)
  }
  updateIsNew(payload: any): Observable<any> {
    return this.service.put(`${this.pathProduct}/is-new`, payload)
  }

  private pathProductDiscounts = '/v1/product-discounts'

  getProductDiscountsList(payload: any): Observable<any> {
    return this.service.post(`${this.pathProductDiscounts}/query`, payload)
  }
  getProductDiscounts(id: any): Observable<any> {
    return this.service.get(`${this.pathProductDiscounts}/${id}`)
  }
  addProductDiscounts(payload: any): Observable<any> {
    return this.service.post(`${this.pathProductDiscounts}`, payload)
  }
  updateProductDiscounts(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathProductDiscounts}/${id}`, payload)
  }
  deleteProductDiscounts(id: any, payload: any = {}): Observable<any> {
    return this.service.delete(`${this.pathProductDiscounts}/${id}`, payload)
  }
  deleteBulkProductDiscounts(payload: any = {}): Observable<any> {
    return this.service.post(`${this.pathProductDiscounts}/bulk-delete`, payload)
  }

  private pathProductFlashSales = '/v1/flash-sales'

  getProductFlashSalesList(payload: any): Observable<any> {
    return this.service.post(`${this.pathProductFlashSales}/query`, payload)
  }
  getProductFlashSales(id: any): Observable<any> {
    return this.service.get(`${this.pathProductFlashSales}/${id}`)
  }
  addProductFlashSales(payload: any): Observable<any> {
    return this.service.post(`${this.pathProductFlashSales}`, payload)
  }
  updateProductFlashSales(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathProductFlashSales}/${id}`, payload)
  }
  deleteProductFlashSales(id: any, payload: any = {}): Observable<any> {
    return this.service.delete(`${this.pathProductFlashSales}/${id}`, payload)
  }
  deleteBulkProductFlashSales(payload: any = {}): Observable<any> {
    return this.service.post(`${this.pathProductFlashSales}/bulk-delete`, payload)
  }

  private pathProductWishList = '/v1/manage-wishlists'

  getProductWishList(payload: any): Observable<any> {
    return this.service.post(`${this.pathProductWishList}/query`, payload)
  }
  sendMail(payload: any): Observable<any> {
    return this.service.post(`${this.pathProductWishList}/send-mail`, payload)
  }
  cancelWishList(payload: any): Observable<any> {
    return this.service.post(`${this.pathProductWishList}/cancel`, payload)
  }
  getNotificationWishList(): Observable<any> {
    return this.service.get(`${this.pathProductWishList}/noti`)
  }

  checkIsCms(product_id: any) {
    return this.service.get(`${this.pathProduct}/is-cms-product/${product_id}`)
  }
  checkMinStock(payload: any): Observable<any> {
    return this.service.post(`${this.pathProduct}/is-min-stock`, payload)
  }
}
