import { Injectable } from '@angular/core'
import { Loading } from './loading'
import { AuthService } from '../services'
import {
  COMPANY_ID,
  COMPANY_NAME,
  Logout,
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from '../helpers'
import { JwtHelperService } from '@auth0/angular-jwt'
import { Service } from '../services/service'
import { environment } from '../../environments/environment'

@Injectable({ providedIn: 'root' })
export class Profile {
  #accessRoles: any[] = []
  readonly #state: any = {
    resProfile: null,
    id: null,
    email: '',
    first_name: '',
    last_name: '',
    code: '',
    tel: '',
    internal_tel: '',
    address: '',
    signature_file_id: null,
    signature_file: {},
    roles: [],
    role: {},
    position: {},
    is_system: false,
  }

  permissions = new Permissions(this)

  $checkToKenInt: any
  constructor(public Loading: Loading, private authService: AuthService, private service: Service) {
    this.$checkToKenInt = setInterval(async () => {
      //check token expired
      let access_token = getLocalStorage('access_token')
      // console.log('access_token', access_token)
      if (access_token) {
        const helper = new JwtHelperService()
        const isExpired = helper.isTokenExpired(access_token)
        // console.log('isExpired', isExpired)
        if (isExpired) {
          const refresh_token = getLocalStorage('refresh_token')
          if (refresh_token) {
            this.getToken(refresh_token)
          }
        }
      }
    }, 10000)
  }

  ngOnDestroy(): void {
    if (this.$checkToKenInt) {
      clearInterval(this.$checkToKenInt)
    }
  }

  get companyId() {
    return this.service.companyId
  }

  get companyName() {
    return COMPANY_NAME[this.service.companyId]
  }

  get companies() {
    return this.service.companies
  }

  get isLogin() {
    return !!this.#state.id
  }

  get id() {
    return this.#state.id
  }

  get userTypeName() {
    return this.#state.user_type_name.toLowerCase()
  }

  get isPasswordChanged() {
    return this.#state.is_password_changed
  }

  get image() {
    return this.#state.user_file?.file_url || ''
  }

  get email() {
    return this.#state.email
  }

  get fullName() {
    return this.#state.first_name ? this.#state.first_name + ' ' + this.#state.last_name : ''
  }

  get user_global_id() {
    return this.#state.user_global_id
  }

  get code() {
    return this.#state.code
  }

  get tel() {
    return this.#state.tel
  }

  get internal_tel() {
    return this.#state.internal_tel
  }

  get address() {
    return this.#state.address
  }

  get signatureFileId() {
    return this.#state.signature_file_id
  }

  get signatureFileName() {
    return this.#state.signature_file.name
  }

  get signatureFileUrl() {
    return this.#state.signature_file.file_url
  }

  get positionName() {
    return this.#state.position.name
  }

  get roles() {
    return this.#state.roles
  }

  get role() {
    return this.#state.role
  }

  get isSuperAdmin() {
    return this.#state.is_system
  }

  get roleName() {
    return this.isSuperAdmin ? 'Super Admin' : this.role?.name || ''
  }

  checkSuperAdminForEdit(isAsset: Boolean) {
    return this.isSuperAdmin || isAsset
  }

  checkSuperAdminForDisabled(isDisabled: Boolean): any {
    return !this.isSuperAdmin && isDisabled
  }

  setProfile(data: any) {
    for (const key in this.#state) {
      const value = data[key]
      if (value) {
        this.#state[key] = value
      }
    }
  }

  async getProfile(force: boolean = false) {
    if (this.isLogin && !force) return
    // --------------Check router to go--------------

    let access_token = getLocalStorage('access_token')
    if (access_token) {
      await new Promise(resolveInner => {
        this.Loading.start()
        this.authService.getProfile().subscribe(res => {
          // const res = temp_user;
          if (!res.is_error) {
            if (res.data) {
              this.#accessRoles = res.data.role?.applications || []
              this.#state.resProfile = res
              this.setProfile(res.data)

              this.service.setCompanyString(res.data.companies)
            }
          } else {
            if ((environment as any).test) {
              console.log('refresh_token: ', getLocalStorage('refresh_token'))

              // alert('getProfile')
              // alert('getProfile1')
            }
            localStorage.clear()
          }
          this.Loading.stop()
          resolveInner(null)
        })
      })
    }
  }

  async getToken(refresh_token: string) {
    return await new Promise(resolveInner => {
      const payload = {
        refresh_token,
      }

      this.authService.refreshToken(payload).subscribe(res => {
        console.log('refreshToken', res)

        if (res && !res.is_error) {
          setLocalStorage('access_token', res.data.access_token)
          setLocalStorage('refresh_token', res.data.refresh_token)

          //send to extension
          if (this.service.companyId == COMPANY_ID.BHB) {
            window.postMessage({
              key: 'login_from_web',
              value: res.data,
            })
          }

          resolveInner(res.data.refresh_token)
        } else {
          // if ((environment as any).test) {
          //   // alert('test')
          // } else {
          removeLocalStorage('access_token')
          removeLocalStorage('refresh_token')

          //send to extension
          if (this.service.companyId == COMPANY_ID.BHB) {
            window.postMessage({
              key: 'logout_from_web',
              value: null,
            })
          }
          // }

          resolveInner(null)
        }
      })
    })
  }

  accessApplications(): any[] {
    return this.#accessRoles || []
  }

  async logout() {
    this.Loading.start()
    await new Promise(resolveInner => {
      this.authService.logout({}).subscribe(res => {
        this.Loading.stop()
        resolveInner(null)
      })
    })
    Logout()
  }
}

export const APP_PERMISSION = {
  COMPANY: 'A001',
  BANK_COMPANY: 'A002',
  // DISTRIBUTION_CHANNEL: 'A003',
  // DELIVERY_CHANNEL: 'A004',
  PROMOTION: 'A005',
  CUSTOMER: 'A006',
  TIER: 'A007',
  HISTORY: 'A008',
  PRODUCT: 'A009',
  PRODUCT_CATEGORY: 'A010',
  BRAND_PRODUCT: 'A011',
  // STOCK: 'A012',
  ORDER: 'A013',
  REPORT: 'A014',
  USER_MANAGEMENT: 'A015',
  ROLE_AND_PERMISSION: 'A016',
  SUPPLIER: 'A017',
  CARATS: 'A018',
  EXPENSE: 'A019',
  MARKETPLACE: 'A020',
  CURRENCY: 'A021',
  CMS: 'A022',

  // CMS_HOME: 'A21',
  // CMS_CAMPAIGN: 'A22',
  // CMS_COLLECTION: 'A23',
  // CMS_PRODUCT: 'A24',
  // CMS_PRODUCT_DETAILS: 'A25',
  // CMS_TIRE: 'A26',
  // CMS_OUR_BRAND: 'A27',
  // CMS_POLICY: 'A28',
  // CMS_FAQS: 'A29',
} as const

export type EnumAppPermission = (typeof APP_PERMISSION)[keyof typeof APP_PERMISSION]

export const ACTION_ACCESS = {
  VIEW: 'VIEW',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  IMPORT: 'IMPORT',
  EXPORT: 'EXPORT',
} as const

export type EnumActionPermission = (typeof ACTION_ACCESS)[keyof typeof ACTION_ACCESS]

@Injectable({ providedIn: 'root' })
export class Permissions {
  readonly APP = APP_PERMISSION
  readonly ACTION = ACTION_ACCESS

  readonly actionValues = Object.values(this.ACTION)

  //priority = index
  readonly pages = [
    { path: '/order', code: APP_PERMISSION.ORDER },
    { path: '/product', code: APP_PERMISSION.PRODUCT },
    { path: '/report', code: APP_PERMISSION.REPORT },
    { path: '/customer?tab=0', code: APP_PERMISSION.CUSTOMER },
    { path: '/customer?tab=1', code: APP_PERMISSION.SUPPLIER },
    { path: '/record-expenses', code: APP_PERMISSION.EXPENSE },
    { path: '/history', code: APP_PERMISSION.HISTORY },
    { path: '/setting/company-manage/view', code: APP_PERMISSION.COMPANY },
    { path: '/setting/payment-channel', code: APP_PERMISSION.BANK_COMPANY },
    { path: '/setting/promotion', code: APP_PERMISSION.PROMOTION },
    { path: '/setting/tier', code: APP_PERMISSION.TIER },
    { path: '/setting/product-category', code: APP_PERMISSION.PRODUCT_CATEGORY },
    { path: '/setting/product-brand', code: APP_PERMISSION.BRAND_PRODUCT },
    { path: '/setting/user-management?tab=0', code: APP_PERMISSION.USER_MANAGEMENT },
    { path: '/setting/user-management?tab=1', code: APP_PERMISSION.ROLE_AND_PERMISSION },
    { path: '/setting/carats/view', code: APP_PERMISSION.CARATS },
    { path: '/setting/marketplace', code: APP_PERMISSION.MARKETPLACE },
    { path: '/setting/currency', code: APP_PERMISSION.CURRENCY },
    { path: '/setting/cms/home/view', code: APP_PERMISSION.CMS },
    // { path: '/setting/cms/campaign/view', code: APP_PERMISSION.CMS },
    // { path: '/setting/cms/collection', code: APP_PERMISSION.CMS },
    // { path: '/setting/cms/product', code: APP_PERMISSION.CMS },
    // { path: '/setting/cms/product-details', code: APP_PERMISSION.CMS },
    // { path: '/setting/cms/tier', code: APP_PERMISSION.CMS },
    // { path: '/setting/cms/our-brand', code: APP_PERMISSION.CMS },
    // { path: '/setting/cms/policy', code: APP_PERMISSION.CMS },
    // { path: '/setting/cms/faqs', code: APP_PERMISSION.CMS },
  ] as const

  constructor(public Profile: Profile) {}

  defaultPage() {
    const applications = this.Profile.accessApplications()
    return this.pages.find(page =>
      applications.some(app => app.code == page.code && this.checkAccess(app.code))
    )
  }

  checkAccess(
    applicationCodes: EnumAppPermission[],
    actions: EnumActionPermission[] = this.actionValues
  ) {
    const apps = this.Profile.accessApplications().filter(app =>
      applicationCodes.includes(app.code)
    )
    return apps.some(app =>
      (app.application_permissions || []).some(
        (access: any) => access.permission.is_selected && actions.includes(access.permission.code)
      )
    )
  }

  defaultSetting() {
    const appCode = [
      APP_PERMISSION.COMPANY,
      APP_PERMISSION.BANK_COMPANY,
      APP_PERMISSION.PROMOTION,
      APP_PERMISSION.TIER,
      APP_PERMISSION.CARATS,
      APP_PERMISSION.PRODUCT_CATEGORY,
      APP_PERMISSION.BRAND_PRODUCT,
      APP_PERMISSION.USER_MANAGEMENT,
      APP_PERMISSION.ROLE_AND_PERMISSION,
      APP_PERMISSION.MARKETPLACE,
      APP_PERMISSION.CURRENCY,
      APP_PERMISSION.CMS,
      // APP_PERMISSION.CMS_HOME,
      // APP_PERMISSION.CMS_CAMPAIGN,
      // APP_PERMISSION.CMS_PRODUCT,
      // APP_PERMISSION.CMS_PRODUCT_DETAILS,
      // APP_PERMISSION.CMS_TIRE,
      // APP_PERMISSION.CMS_OUR_BRAND,
      // APP_PERMISSION.CMS_POLICY,
      // APP_PERMISSION.CMS_FAQS,
    ].find(appCode => this.checkAccess([appCode]))

    return this.pages.find(page => page.code == appCode)
  }
}
