import { Component } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSlideToggle } from '@angular/material/slide-toggle'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTooltipModule } from '@angular/material/tooltip'
import { DomSanitizer } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { CheckboxAllTableComponent } from '../../../components/checkbox-table/checkbox-all-table.component'
import { CheckboxTableComponent } from '../../../components/checkbox-table/checkbox-table.component'
import { InputComponent } from '../../../components/input/input'
import { LabelTierColorComponent } from '../../../components/label-tier-color/label-tier-color'
import { ModalDeleteComponent } from '../../../components/modal-delete/modal-delete'
import { SearchComponent } from '../../../components/search/search'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { StatusUserComponent } from '../../../components/status-user/status-user'
import { ACTION_ACCESS, APP_PERMISSION, FilterTableTier, Loading, Profile } from '../../../globals'
import { Moment, Pagination, formatPrice } from '../../../helpers'
import { imports } from '../../../imports'
import { TierService } from '../../../services'
import { ModalTierComponent } from './modal-tier/modal-tier'
import { SearchFilterComponent } from './search-filter/search-filter'
@Component({
  selector: 'app-tier',
  standalone: true,
  imports: [
    ...imports,
    StatusUserComponent,
    SearchComponent,
    MatPaginatorModule,
    MatTooltipModule,
    MatTabsModule,
    MatSlideToggle,
    InputComponent,
    CheckboxAllTableComponent,
    CheckboxTableComponent,
    SearchFilterComponent,
    LabelTierColorComponent,
  ],
  templateUrl: './tier.html',
  styleUrls: ['./tier.scss'],
})
export class TierComponent {
  readonly Moment = Moment
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice

  readonly paginationTier = new Pagination({
    sortName: 'name',
  })
  tierList: any[] = []

  constructor(
    public profile: Profile,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public Router: Router,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public tierService: TierService,
    public filterTableTier: FilterTableTier,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.getTierList()
  }

  getTierList() {
    const pagination = this.paginationTier.get()
    const dataFilter = this.filterTableTier.getData()
    const payload = {
      ...pagination,
      ...dataFilter,
    }
    // console.log(payload)
    // return

    this.loading.start()
    this.tierService.getTierList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.tierList = res.data.records
          this.paginationTier.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  openModalTier(title: string, type: 'add' | 'edit', dataManage?: any) {
    const dialogRef = this.dialog.open(ModalTierComponent, {
      disableClose: true,
      data: {
        title,
        type,
        dataManage,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getTierList()
      }
    })

    return dialogRef
  }

  confirmDelete(dataManage: any) {
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบ “${dataManage.name}” ออกจากระดับสมาชิกใช่หรือไม่`,
      },
    })

    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        this.deleteTier(dataManage)
      }
    })

    return dialogRefDelete
  }

  deleteTier(dataManage: any) {
    this.loading.start()
    this.tierService.deleteTier(dataManage.id).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('ลบข้อมูลสำเร็จ')
          this.getTierList()
        } else {
          this.customSnackBar.fail(res.message || 'ลบข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }
}
