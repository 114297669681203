<div class="search-filter-wrapper">
  <app-input-search
    [control]="filterTableProduct.search"
    placeholder="{{placeholder || ''}}"
    (onEnter)="filterTableProduct.confirm(); onConfirm.emit()"></app-input-search>

  @if (tab == 0 || tab == 3) {
  <button (click)="tab == 3 ? openModalWishListFilter() : openModalFilter()" matRipple class="btn btn-outline-dark">
    <span class="icon material-symbols-outlined fill">filter_list</span>ตัวกรอง
  </button>
  }
</div>

@if (filterTableProduct.isFilter || filterTableProduct.isFilterWishList) {
<div class="filter-wrapper mb-3">
  <div class="filter-title-wrapper">
    <div class="filter-title">ตัวกรอง</div>
    <div class="filter-separator">|</div>
    <button
      matRipple
      class="btn btn-transparent btn-clear"
      (click)="filterTableProduct.reset(); afterFilter()">
      ล้างค่า
    </button>
  </div>

  <div class="filter-tag-wrapper">
    @for (d of filterTableProduct.filter['status_product_ids']; track $index) {
    <div class="filter-tag-item">
      <div class="filter-tag">{{ getName(d, status_product_ids_list)?.name }}</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterTableProduct.remove(d, 'status_product_ids'); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    } @for (d of filterTableProduct.filter['status_stock_ids']; track $index) {
    <div class="filter-tag-item">
      <div class="filter-tag">{{ getName(d, status_stock_ids_list)?.name }}</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterTableProduct.remove(d, 'status_stock_ids'); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    } @for (d of filterTableProduct.filter['status_ids']; track $index) {
    <div class="filter-tag-item">
      <div class="filter-tag">{{ getName(d, status_ids_list)?.name }}</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterTableProduct.remove(d, 'status_ids'); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    }
  </div>
</div>
}
