<div class="tabs-wrapper">
  <mat-tab-group
    animationDuration="0ms"
    [disableRipple]="true"
    [selectedIndex]="tab"
    (selectedIndexChange)="changeTab($event)">
    <mat-tab label="ข้อมูลทั่วไป">
      <div class="tabs-content-wrapper">
        <div class="top-content-wrapper">
          <div class="top-content-left">
            <app-search-filter-payment
              placeholder="ค้นหา"
              (onConfirm)="paginationHistory.reset();getList();itemChecked.clear()"></app-search-filter-payment>
          </div>
          <div class="top-content-right mb-3">
            <div class="date-range-wrapper">
              <label class="label"> วันที่ทำรายการ </label>

              <app-date-picker-range
                [controlStr]="form.controls['start_date']"
                [controlEnd]="form.controls['end_date']"
                [hideError]="true"
                (onChange)="paginationHistory.reset();getList();itemChecked.clear()"></app-date-picker-range>
            </div>

            @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW])) {
            <button
              matRipple
              class="btn btn-outline-secondary"
              (click)="openModalDownloadFile('ดาวน์โหลดไฟล์การชำระเงิน', { message: 'คุณต้องการดาวน์โหลดไฟล์การชำระเงินที่เลือกใช่หรือไม่' })">
              <span class="icon material-symbols-outlined fill">download_2</span>
              ส่งออกไฟล์
            </button>
            }
          </div>
        </div>

        <div class="table-responsive table-main-tabs-wrapper">
          <table class="table table-fixed-column">
            <thead>
              <tr class="tr-vertical-top">
                @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY],
                [ACTION_ACCESS.VIEW])) {
                <th width="70" class="colum-checkbox">
                  <app-checkbox-all-table
                    [checkedValue]="itemChecked"
                    [datas]="historyList"
                    fieldValue="id"></app-checkbox-all-table>
                </th>
                }
                <th width="150">เลขที่ออเดอร์</th>
                <th width="230">ชื่อลูกค้า</th>
                <th width="200">รหัสการทำรายการ</th>
                <th width="200">ช่องทางการชำระเงิน</th>
                <th width="200">ธนาคารรับโอน</th>
                <th width="150" class="text-end">ยอดชำระ</th>
                <th width="150">
                  <app-sort-by
                    key="payment_date"
                    [pagination]="paginationHistory"
                    (onChange)="getList()">
                    วันที่ชำระเงิน
                  </app-sort-by>
                </th>
                <th width="150">สถานะรายการ</th>
              </tr>
            </thead>

            <tbody>
              @for (item of historyList; track item) {
              <tr class="tr-vertical-top">
                @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY],
                [ACTION_ACCESS.VIEW])) {
                <td class="colum-checkbox">
                  <app-checkbox-table
                    [checkedValue]="itemChecked"
                    [value]="item.id"></app-checkbox-table>
                </td>
                }
                <td>{{ item.order_no || '-' }}</td>
                <td>
                  <app-text-tooltip>
                    {{ showCustomerName(item.customer_name, item.customer_last_name) }}
                  </app-text-tooltip>
                </td>
                <td>
                  @for (d of item.payment_details; track $index) {
                  <!-- ~ -->
                  @if (d.transaction_id) {
                  <app-text-tooltip> {{ d.transaction_id || '-' }} </app-text-tooltip>
                  } @else {
                  <div>-</div>
                  }
                  <!-- ~ -->
                  }
                </td>
                <td>
                  @for (d of item.payment_details; track $index) {
                  <div>{{ d.payment_name}}</div>
                  } @empty {
                  <div>-</div>
                  }
                </td>
                <td>
                  @for (d of item.payment_details; track $index) {
                  <div class="d-flex align-items-start">
                    @if (d.payment_type == 1) {
                      @if (d.bank_account.bank.code != 'NONE') {
                        <img
                        [alt]="d.bank_account.bank.name"
                        src="/assets/images/logos/banks/{{ d.bank_account.bank.code }}.png"
                        width="20"
                        class="me-1" />
                      }
                    <app-text-tooltip> {{ d.bank_account.bank.name || '-' }} </app-text-tooltip>
                    } @else if (d.payment_type == 2) {
                    <div>-</div>
                    } @else if (d.payment_type == 3 || d.payment_type == 4) {
                    <div>Beam payment</div>
                    } @else {
                    <div>-</div>
                    }
                  </div>
                  } @empty {
                  <div>-</div>
                  }
                </td>
                <td class="text-end">
                  @for (d of item.payment_details; track $index) {
                  <div>{{ formatPrice(d.payment_amount) }}</div>
                  } @empty {
                  <div>-</div>
                  }
                </td>
                <td>
                  @for (d of item.payment_details; track $index) {
                  <div>
                    {{ d.payment_date ? Moment(d.payment_date).format('DD-MM-YYYY HH:mm') : '-' }}
                  </div>
                  } @empty {
                  <div>-</div>
                  }
                </td>
                <td>
                  <app-status [status]="item.is_success ? 3 : 1">
                    {{ item.is_success ? 'สำเร็จ' : 'ไม่สำเร็จ' }}
                  </app-status>
                </td>
              </tr>
              }
            </tbody>
            @if (historyList.length) {
            <tfoot class="tfoot-fixed">
              <tr class="tr-sum-total">
                <td
                  [attr.colspan]="profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW]) ? 2 : 1">
                  รวม
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-end">
                  {{ formatPrice(sumField('payment_details.payment_amount')) }}
                </td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
            }
          </table>

          @if(!historyList.length) {
          <div class="data-not-found-wrapper">ไม่พบข้อมูลการชำระเงิน</div>
          }
        </div>

        <!-- Pagination -->
        @if (historyList.length) {
        <div class="pagination-wrapper">
          <mat-paginator
            [pageIndex]="paginationHistory.data.pageIndex"
            [length]="paginationHistory.data.length"
            [pageSize]="paginationHistory.data.pageSize"
            [pageSizeOptions]="paginationHistory.data.pageSizeOptions"
            aria-label="Select page"
            (page)="paginationHistory.setFromPaginator($event);getList()">
          </mat-paginator>
        </div>
        }
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
