import { Component, OnInit, Renderer2 } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { InputComponent } from '../../components/input/input'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import {
  COMPANY_ID,
  EmailReg,
  ValidateForm,
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from '../../helpers'
import { InputPasswordComponent } from '../../components/input-password/input-password'
import { AlertMessageComponent } from '../../components/alert-message/alert-message.component'
import { AuthService } from '../../services'
import { Loading, Profile } from '../../globals'
import { CustomSnackBar } from '../../components/snackbar/snackbar'
import { imports } from '../../imports'
import { Service } from '../../services/service'
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [...imports, InputComponent, InputPasswordComponent, AlertMessageComponent],
  templateUrl: './login.html',
  styleUrls: ['./login.scss'],
})
export class LoginComponent implements OnInit {
  readonly COMPANY_ID = COMPANY_ID

  form: 'login' | 'forgot' = 'login'
  failLogin = ''
  successForgot = ''

  companyId = new FormControl(COMPANY_ID.BHB)
  formLogin = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(EmailReg)]),
    password: new FormControl('', [Validators.required]),
  })

  emailForgot = new FormControl('', [Validators.required, Validators.pattern(EmailReg)])

  forgotForm = new FormGroup({
    email: this.emailForgot,
  })

  readonly companys = [
    {
      id: COMPANY_ID.BHB,
      name: 'Brandname Hunter',
    },
    {
      id: COMPANY_ID.PANIA,
      name: 'Pania',
    },
  ]

  constructor(
    private renderer: Renderer2,
    public profile: Profile,
    public loading: Loading,
    public authService: AuthService,
    public customSnackBar: CustomSnackBar,
    private route: ActivatedRoute,
    public service: Service
  ) {
    this.route.queryParamMap.subscribe((queryParams: any) => {
      this.resetToken(queryParams.params.redirect)
    })
  }

  ngOnInit(): void {}

  onChangeCompany() {
    const companyId = this.companyId.value || COMPANY_ID.BHB
    this.service.setCompanyId(companyId)
    for (const c of this.companys) {
      this.renderer.removeClass(document.body, 'company-' + c.id)
    }
    this.renderer.addClass(document.body, 'company-' + companyId)
    this.failLogin = ''
    this.successForgot = ''
    this.emailForgot.updateValueAndValidity()
  }

  resetToken(redirect: any) {
    const refresh_token = getLocalStorage('refresh_token')
    if (refresh_token) {
      this.loading.start()
      this.profile.getToken(refresh_token).then((new_refresh_token: any) => {
        if (new_refresh_token) {
          location.href = redirect || '/'
        } else {
          localStorage.clear()
          sessionStorage.clear()
          this.onChangeCompany()

          //send to extension
          window.postMessage({
            key: 'logout_from_web',
            value: null,
          })
        }
        this.loading.stop()
      })
    } else {
      localStorage.clear()
      sessionStorage.clear()
      this.onChangeCompany()

      //send to extension
      window.postMessage({
        key: 'logout_from_web',
        value: null,
      })
    }
  }

  forgot() {
    this.form = 'forgot'
    this.failLogin = ''
  }

  onLogin() {
    ValidateForm(this.formLogin)
    if (!this.formLogin.valid) return

    const value = this.formLogin.getRawValue()
    const payload = {
      email: value.email,
      password: value.password,
    }

    this.failLogin = ''

    this.loading.start()
    this.authService.login(payload).subscribe(async (res: any) => {
      if (res) {
        if (!res.is_error) {
          if (res.data.reset_password_token) {
            //first login
            location.href =
              (this.companyId.value == COMPANY_ID.BHB ? '/bhb' : '/pania') +
              '/reset-password/' +
              res.data.reset_password_token
          } else {
            setLocalStorage('access_token', res.data.access_token)
            setLocalStorage('refresh_token', res.data.refresh_token)
            this.service.setCompanyString(res.data.companies)

            //send to extension
            await new Promise(rev => {
              if (this.service.companyId == COMPANY_ID.BHB) {
                window.postMessage({
                  key: 'login_from_web',
                  value: res.data,
                })
              }

              setTimeout(() => {
                rev(null)
              }, 1000)
            })

            location.href = '/'
          }
        } else {
          this.failLogin = res.message
        }
      }
      this.loading.stop()
    })
  }

  onForgot() {
    ValidateForm(this.forgotForm)
    if (!this.forgotForm.valid) return

    const value = this.forgotForm.getRawValue()
    const payload = {
      email: value.email,
    }

    this.successForgot = ''

    this.loading.start()
    this.authService.forgotPassword(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.successForgot = 'ส่งลิงก์สำหรับรีเซ็ตรหัสผ่านใหม่เรียบร้อย โปรดตรวจสอบอีเมลของท่าน'
        } else {
          this.forgotForm.controls['email'].setErrors({
            fail: true,
          })
        }
      }
      this.loading.stop()
    })
  }

  onCancel() {
    this.forgotForm.reset()
    this.form = 'login'
  }
}
