<div class="tabs-content-wrapper">
  <div class="top-content-wrapper">
    <div class="top-content-left mb-3">
      <app-search
        [control]="filterTableHistoryOrderCanceled.search"
        placeholder="ค้นหา"
        (onEnter)="paginationHistory.reset();getList();itemChecked.clear()"></app-search>
    </div>
    <div class="top-content-right mb-3"></div>
  </div>

  <div class="table-responsive table-main-tabs-wrapper">
    <table class="table table-fixed-column">
      <thead>
        <tr class="tr-vertical-top">
          <th width="160">
            <app-sort-by key="order_no" [pagination]="paginationHistory" (onChange)="getList()">
              เลขที่ออเดอร์
            </app-sort-by>
          </th>
          <th width="200">
            <app-sort-by
              key="customer_name"
              [pagination]="paginationHistory"
              (onChange)="getList()">
              ชื่อลูกค้า
            </app-sort-by>
          </th>
          <th width="160">เบอร์โทรศัพท์</th>
          <th width="160">ช่องทาง</th>
          <th width="180">รหัสสินค้า</th>
          <th width="250">ชื่อสินค้า</th>
          <th width="100" class="text-center">จำนวน</th>
          <th width="160" class="text-end">ราคาต่อหน่วย (บาท)</th>
          <th width="160" class="text-end">รวม (บาท)</th>
          <th width="200">หมายเหตุ</th>
          <th width="200">ธนาคาร</th>
          <th width="160" class="text-end">ราคารวมสินค้า (บาท)</th>
          <th width="160">
            <app-sort-by key="payment_date" [pagination]="paginationHistory" (onChange)="getList()">
              วันที่ชำระเงิน
            </app-sort-by>
          </th>
          <th width="160">
            <app-sort-by key="closed_date" [pagination]="paginationHistory" (onChange)="getList()">
              วันที่ยกเลิก
            </app-sort-by>
          </th>
          <th width="160" class="text-center">หลักฐานการโอนเงิน</th>
        </tr>
      </thead>

      <tbody>
        @for (item of historyList; track item) {
        <tr class="tr-vertical-top">
          <td>{{ item.order_no }}</td>
          <td>
            <app-text-tooltip>{{ showCustomerName(item.customer_name, item.customer_last_name) }}</app-text-tooltip>
          </td>
          <td>{{ item.telephone }}</td>
          <td>{{ item.channel?.name || '-' }}</td>
          <td class="px-0 py-3" colspan="5">
            <table class="w-100">
              <colgroup>
                <col width="180" />
                <col width="250" />
                <col width="100" />
                <col width="160" />
                <col width="160" />
              </colgroup>

              <tbody>
                @for (product of item.products; track $index) {
                <tr class="tr-sub-product">
                  <td>{{ product.item.code || '-' }}</td>
                  <td>{{ product.item.name || '-' }}</td>
                  <td class="text-center">{{ formatPriceAutoDecimal(product.quantity) }}</td>
                  <td class="text-end">{{ formatPrice(product.price) }}</td>
                  <td class="text-end">{{ formatPrice(product.total_price) }}</td>
                </tr>
                }
              </tbody>
            </table>
          </td>
          <td>{{ item.remark || '-' }}</td>
          <td>{{ item.bank_account_name || '-' }}</td>
          <td class="text-end">{{ formatPrice(item.total_price) }}</td>
          <td>
            {{ item.payment_date ? Moment(item.payment_date).format('DD-MM-YYYY HH:mm') : '-' }}
          </td>
          <td>
            {{ item.cancel_at ? Moment(item.cancel_at).format('DD-MM-YYYY HH:mm') : '-' }}
          </td>
          <td class="text-center">
            <span
              class="color--brand-blue cursor-pointer"
              (click)="openModalSlip('หลักฐานการโอนเงิน', item)">
              ดูรูปภาพ
            </span>
          </td>
        </tr>
        }
      </tbody>
      @if (historyList.length) {
      <tfoot class="tfoot-fixed">
        <tr class="tr-sum-total">
          <td>รวม</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-end">{{ formatPrice(sumField('products.total_price')) }}</td>
          <td class="text-end"></td>
          <td class="text-end"></td>
          <td class="text-end">{{ formatPrice(sumField('total_price')) }}</td>
          <td class="text-end"></td>
          <td class="text-end"></td>
          <td class="text-end"></td>
        </tr>
      </tfoot>
      }
    </table>

    @if(!historyList.length) {
    <div class="data-not-found-wrapper">ไม่พบข้อมูลรายการที่ยกเลิก</div>
    }
  </div>

  <!-- Pagination -->
  @if (historyList.length) {
  <div class="pagination-wrapper">
    <mat-paginator
      [pageIndex]="paginationHistory.data.pageIndex"
      [length]="paginationHistory.data.length"
      [pageSize]="paginationHistory.data.pageSize"
      [pageSizeOptions]="paginationHistory.data.pageSizeOptions"
      aria-label="Select page"
      (page)="paginationHistory.setFromPaginator($event);getList()">
    </mat-paginator>
  </div>
  }
</div>
