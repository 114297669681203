<div class="table-responsive-detail mt-3">
  <table class="table table-fixed-column table-detail">
    <thead>
      <tr>
        <th width="200">ผู้ขาย</th>
        <th width="250" class="text-end">ต้นทุนต่อหน่วย (บาท)</th>
        <th width="250" class="text-end">คงเหลือ</th>
        <th width="250" class="text-center">สต๊อกเว็บ</th>
        <th width="200">อัปเดตโดย</th>
        <th width="170">วันที่อัปเดต</th>
        @if (product.action_stock.edit) {
        <th width="250" class="fixed box-shadow-left">จัดการ</th>
        }
      </tr>
    </thead>
    <tbody>
      @for (item of productStockList; track $index) {
      <tr>
        <td>{{ item.data.supplier?.name || '-' }}</td>
        <td>
          @if (!item.isEdit) {
          <div class="d-flex align-items-center justify-content-end">
            {{ formatPrice(item.data.cost_price) }}
            <!-- ~ -->
            @if (product.action_stock.edit) {
            <button matRipple class="btn btn-transparent-outline ms-1" (click)="item.isEdit = true">
              <span
                class="icon icon-20 material-symbols-outlined fill"
                matTooltip="แก้ไข"
                matTooltipPosition="above"
                matTooltipClass="tooltip-black"
                >edit</span
              >
            </button>
            }
          </div>
          } @else {
          <div class="d-flex align-items-center justify-content-end">
            <div class="w-50">
              <app-input-number [control]="item.cost_price" [min]="0">
                @if (item.cost_price.hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (item.cost_price.hasError('max')) {
                <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
                }
              </app-input-number>
            </div>
            <span
              matRipple
              class="icon icon-24 material-symbols-outlined fill red mx-2 mb-4 cursor-pointer"
              (click)="item.isEdit = false;item.cost_price.setValue(item.data.cost_price)"
              >cancel</span
            >
            <span
              matRipple
              class="icon icon-24 material-symbols-outlined fill green mb-4 cursor-pointer"
              (click)="onSaveCostPrice(item)"
              >check_circle</span
            >
          </div>
          }
        </td>
        <td class="text-end">{{ formatPriceAutoDecimal(item.data.stock) }}</td>
        <td class="text-center">{{ formatPriceAutoDecimal(item.data.stock_web) }}</td>
        <td>{{ item.data.updated_by ? item.data.updated_by.full_name : '' }}</td>
        <td>
          {{ item.data.updated_at ? Moment(item.data.updated_at).format('DD-MM-YYYY, HH:mm') : '-'
          }}
        </td>
        @if (product.action_stock.edit) {
        <td class="fixed box-shadow-left">
          <div class="d-flex gap-3">
            <button
              matRipple
              [disabled]="!item.data.is_update_stock"
              (click)="openModalAdjustStock('ปรับสต๊อก', item.data)"
              class="btn btn-outline-secondary">
              ปรับสต๊อก
            </button>
            <button matRipple class="btn btn-outline-secondary" (click)="updateToWeb(item.data.id)">
              อัปเดตขึ้นเว็บ
            </button>
          </div>
        </td>
        }
      </tr>
      }
    </tbody>
  </table>
  @if(!productStockList.length) {
  <div class="data-not-found-wrapper">ไม่พบข้อมูล</div>
  }
</div>
@if(productStockList.length) {
<div class="pagination-wrapper">
  <mat-paginator
    [pageIndex]="paginationProductStock.data.pageIndex"
    [length]="paginationProductStock.data.length"
    [pageSize]="paginationProductStock.data.pageSize"
    [pageSizeOptions]="paginationProductStock.data.pageSizeOptions"
    (page)="paginationProductStock.setFromPaginator($event);getProductStockList()"
    aria-label="Select page">
  </mat-paginator>
</div>
}
