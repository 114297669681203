<div class="tabs-wrapper">
  <mat-tab-group
    animationDuration="0ms"
    [disableRipple]="true"
    [selectedIndex]="tab"
    (selectedIndexChange)="changeTab($event)">
    <mat-tab label="ข้อมูลทั่วไป">
      <div class="tabs-content-wrapper">
        <div class="top-content-wrapper">
          <div class="top-content-left mb-3">
            <app-search
              [control]="filterTableHistoryOrderReturn.search"
              placeholder="ค้นหา"
              (onEnter)="filterTableHistoryOrderReturn.confirm();paginationHistory.reset();getList();itemChecked.clear()"></app-search>
          </div>
          <div class="top-content-right mb-3">
            <div class="date-range-wrapper">
              <label class="label"> วันที่ทำรายการ </label>

              <app-date-picker-range
                [controlStr]="form.controls['start_date']"
                [controlEnd]="form.controls['end_date']"
                [hideError]="true"
                (onChange)="paginationHistory.reset();getList();itemChecked.clear()"></app-date-picker-range>
            </div>

            @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW])) {
            <button
              matRipple
              class="btn btn-outline-secondary"
              (click)="openModalDownloadFile('ดาวน์โหลดไฟล์รายการคืนสินค้า', { message: 'คุณต้องการดาวน์โหลดไฟล์รายการคืนสินค้าที่เลือกใช่หรือไม่' })">
              <span class="icon material-symbols-outlined fill">download_2</span>
              ส่งออกไฟล์
            </button>
            }
          </div>
        </div>

        <div class="table-responsive table-main-tabs-wrapper">
          <table class="table table-fixed-column">
            <thead>
              <tr class="tr-vertical-top">
                @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY],
                [ACTION_ACCESS.VIEW])) {
                <th width="70" class="colum-checkbox">
                  <app-checkbox-all-table
                    [checkedValue]="itemChecked"
                    [datas]="historyList"
                    fieldValue="id"></app-checkbox-all-table>
                </th>
                }
                <th width="120">เลขที่ออเดอร์</th>
                <th width="200">ชื่อลูกค้า</th>
                <th width="250">ชื่อสินค้า</th>
                <th width="100" class="text-center">จำนวนสินค้า</th>
                <th width="130" class="text-end">จำนวนเงิน (บาท)</th>
                <th width="200">ทำรายการโดย</th>
                <th width="170">
                  <app-sort-by
                    key="created_at"
                    [pagination]="paginationHistory"
                    (onChange)="getList()">
                    วันที่ทำรายการ
                  </app-sort-by>
                </th>
              </tr>
            </thead>

            <tbody>
              @for (item of historyList; track item) {
              <tr class="tr-vertical-top">
                @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY],
                [ACTION_ACCESS.VIEW])) {
                <td class="colum-checkbox">
                  <app-checkbox-table
                    [checkedValue]="itemChecked"
                    [value]="item.id"></app-checkbox-table>
                </td>
                }
                <td>{{ item.order_no || '-' }}</td>
                <td>
                  <app-text-tooltip
                    >{{ showCustomerName(item.customer_name, item.customer_last_name)
                    }}</app-text-tooltip
                  >
                </td>
                <td colspan="2" class="px-0">
                  <table class="w-100">
                    <colgroup>
                      <col width="250" />
                      <col width="100" />
                    </colgroup>

                    <tbody>
                      <tr class="tr-sub-product">
                        <td>
                          <app-text-tooltip>{{ item.return_product_name || '-' }}</app-text-tooltip>
                        </td>
                        <td class="text-center">
                          {{ formatPriceAutoDecimal(item.return_quantity) }}
                        </td>
                      </tr>
                      <!-- <tr class="tr-sub-product">
                        <td>{{ item.origin_product_name }}</td>
                        <td class="text-center">
                          {{ formatPriceAutoDecimal(item.origin_quantity) }}
                        </td>
                      </tr> -->
                    </tbody>
                  </table>
                </td>
                <td class="text-end">{{ formatPrice(item.total_price) }}</td>
                <td>{{ item.created_by?.full_name || '-' }}</td>
                <td>
                  {{ item.created_at ? Moment(item.created_at).format('DD-MM-YYYY HH:mm') : '-' }}
                </td>
              </tr>
              }
            </tbody>
            @if (historyList.length) {
            <tfoot class="tfoot-fixed">
              <tr class="tr-sum-total">
                <td
                  [attr.colspan]="profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW]) ? 2 : 1">
                  รวม
                </td>
                <td></td>
                <td></td>
                <td class="text-center">
                  {{ formatPriceAutoDecimal(sumField('return_quantity')) }}
                </td>
                <td class="text-end">{{ formatPrice(sumField('total_price')) }}</td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
            }
          </table>

          @if(!historyList.length) {
          <div class="data-not-found-wrapper">ไม่พบข้อมูลรายการคืนสินค้า</div>
          }
        </div>

        <!-- Pagination -->
        @if (historyList.length) {
        <div class="pagination-wrapper">
          <mat-paginator
            [pageIndex]="paginationHistory.data.pageIndex"
            [length]="paginationHistory.data.length"
            [pageSize]="paginationHistory.data.pageSize"
            [pageSizeOptions]="paginationHistory.data.pageSizeOptions"
            aria-label="Select page"
            (page)="paginationHistory.setFromPaginator($event);getList()">
          </mat-paginator>
        </div>
        }
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
