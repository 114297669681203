export const formatPrice = (num: any) => {
  num = num || 0
  return !isNaN(num)
    ? (Math.round(num * 100) / 100)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : num
}

export const formatPriceAutoDecimal = (num: any) =>
  num && !isNaN(num)
    ? parseFloat(parseFloat(num.toString()).toFixed(2))
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : num

export const formatNumber = (num: any) =>
  num && typeof num == 'string' ? parseFloat(num.replace(/\,/g, '')) : num

export const formatNoDecimal = (num: any) => {
  num = num || 0
  return !isNaN(num) ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : num
}

//display number with 2 decimal
export const formatTwoDecimal = (num: any) =>
  num && !isNaN(num) ? (Math.round(num * 100) / 100).toFixed(2) : num

export const formatQty = (num: any) =>
  num && typeof num == 'string' ? parseInt(num.split(',').join('')) : num

export const formatNumberToShort = (value: number) => {
  if (value >= 1_000_000) {
    return formatNoDecimal((value / 1_000_000).toFixed(1).replace(/\.0$/, '')) + 'm'
  } else if (value >= 1_000) {
    return formatNoDecimal((value / 1_000).toFixed(1).replace(/\.0$/, '')) + 'k'
  } else {
    return formatNoDecimal(value) // ถ้าค่าน้อยกว่า 1,000 ให้แสดงตามปกติ
  }
}
