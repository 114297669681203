import { Component } from '@angular/core'
import { MatTabsModule } from '@angular/material/tabs'

import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatTooltipModule } from '@angular/material/tooltip'
import { FormControl, FormGroup } from '@angular/forms'
import { imports } from '../../../../imports'
import { SearchComponent } from '../../../../components/search/search'
import { DatePickerRangeComponent } from '../../../../components/date-picker-range/date-picker-range'
import { CheckboxAllTableComponent } from '../../../../components/checkbox-table/checkbox-all-table.component'
import { CheckboxTableComponent } from '../../../../components/checkbox-table/checkbox-table.component'
import { SortByComponent } from '../../../../components/sort-by/sort-by'
import {
  DownloadFileBlob,
  Moment,
  Pagination,
  convertDateToApi,
  formatPrice,
  formatPriceAutoDecimal,
  getSessionStorage,
  setSessionStorage,
} from '../../../../helpers'
import {
  ACTION_ACCESS,
  APP_PERMISSION,
  FilterTableHistoryOrder,
  FilterTableHistoryOrderDeleted,
  Loading,
  Profile,
} from '../../../../globals'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { ModalDownloadFileComponent } from '../../../../components/modal-download-file/modal-download-file'
import { HistoryService } from '../../../../services/history.service'
import { ModalSlipComponent } from './modal-slip/modal-slip'
import { showCustomerName } from '../../../../helpers/customer'
import { FilterTableHistoryOrderCanceled } from '../../../../globals/search-and-filter/history-order-cancel'

@Component({
  selector: 'app-history-order-canceled-list',
  standalone: true,
  imports: [
    ...imports,
    MatTabsModule,
    SearchComponent,
    MatPaginatorModule,
    MatTooltipModule,
    DatePickerRangeComponent,
    CheckboxAllTableComponent,
    CheckboxTableComponent,
    SortByComponent,
  ],
  templateUrl: './history-canceled-list.html',
  styleUrls: ['./history-canceled-list.scss'],
})
export class HistoryOrderCanceledListComponent {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly Moment = Moment
  readonly showCustomerName = showCustomerName

  readonly paginationHistory = new Pagination({
    sortBy: 'desc',
  })
  historyList: any = []
  itemChecked = new Set<any>()

  readonly key_storage_id = 'history/order/canceled/form'

  constructor(
    public route: ActivatedRoute,
    public Router: Router,
    public dialog: MatDialog,
    public profile: Profile,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public filterTableHistoryOrderCanceled: FilterTableHistoryOrderCanceled,
    public historyService: HistoryService
  ) {}

  ngOnInit(): void {
    this.getList()
  }

  getFilter() {
    const dataFilter = this.filterTableHistoryOrderCanceled.getData()

    return dataFilter
  }

  getList(): void {
    const pagination = this.paginationHistory.get()
    const dataFilter = this.getFilter()

    const payload = {
      ...pagination,
      ...dataFilter,
    }

    // console.log(payload)
    // return

    this.loading.start()
    this.historyService.getHistoryOrderCanceledList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.historyList = res.data.records.map((d: any) => {
            d.products.forEach((p: any) => {
              p.item = p.product_detail || p.product
              if (p.delivery_address_uuid) {
                p.delivery_address = d.delivery_addresses.find(
                  (a: any) => a.id == p.delivery_address_id
                )
              }
            })

            return d
          })

          this.paginationHistory.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  openModalSlip(title: string, item: any) {
    const dialogRef = this.dialog.open(ModalSlipComponent, {
      data: {
        title,
        item,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    })

    return dialogRef
  }

  sumField(field: string) {
    return this.historyList.reduce((total: number, item: any) => {
      if (field == 'products.total_price') {
        return (
          total +
          item.products.reduce((t: number, d: any) => {
            return t + (d.total_price || 0)
          }, 0)
        )
      }
      return total + (item[field] || 0)
    }, 0)
  }
}
