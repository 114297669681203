import { Component, Inject, OnInit } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogClose,
  MatDialogActions,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog'
import { PublicService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { Loading, Profile } from '../../../globals'
import { imports } from '../../../imports'
import { MatButtonModule } from '@angular/material/button'
import { Ascending, COMPANY_ID, productImage, productKey } from '../../../helpers'
import { TextTooltipComponent } from '../../../components/text-tooltip/text-tooltip'
import { Service } from '../../../services/service'

@Component({
  selector: 'app-modal-link',
  templateUrl: './modal-link.html',
  styleUrls: ['./modal-link.scss'],
  standalone: true,
  imports: [imports, MatButtonModule, MatDialogContent, TextTooltipComponent],
})
export class ModalLinkComponent implements OnInit {
  readonly COMPANY_ID = COMPANY_ID

  products: any[] = []

  get defaultImage() {
    return this.service.companyId == COMPANY_ID.BHB
      ? '/assets/preview-bhb/images/default-product-image.png'
      : '/assets/preview/images/default-product-image.png'
  }

  constructor(
    public dialogRef: MatDialogRef<ModalLinkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    private service: Service,
    public publicService: PublicService
  ) {}

  ngOnInit() {
    this.getMain()
  }

  getMain() {
    this.loading.start()
    this.publicService.getCMSMain().subscribe(res => {
      this.loading.stop()
      if (!res.is_error) {
        const products =
          this.service.companyId == COMPANY_ID.BHB
            ? res.data.cms_bhb_main_products
            : res.data.cms_pania_main_contents?.[0]?.cms_pania_main_content_products

        this.products = Ascending(products || [], 'seq').reduce((produtcs: any[], item: any) => {
          if (!produtcs.some((p: any) => p.product_id == item.product_id) && produtcs.length < 4) {
            produtcs.push({
              ...item,
              ...(item.product_detail_id ? item.product_detail : item.product),
              image: productImage(item.product, item.product_detail),
              key: productKey(item.product_id, item.product_detail_id),
              seq: item.seq,
            })
          }
          return produtcs
        }, [])
      }
    })
  }

  onClose(): void {
    this.dialogRef.close(true)
  }
}
