import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { imports } from '../../../../imports'
import {
  ACTION_ACCESS,
  APP_PERMISSION,
  FilterTablePrroductSeller,
  Loading,
  Profile,
} from '../../../../globals'
import {
  DownloadFileBlob,
  Moment,
  ORDER_ID,
  Pagination,
  convertDateToApi,
  formatPrice,
  formatPriceAutoDecimal,
  getSessionStorage,
  setSessionStorage,
} from '../../../../helpers'
import { MatDialog } from '@angular/material/dialog'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { ProductService, ProductStockService } from '../../../../services'
import { MatPaginator } from '@angular/material/paginator'
import { ModalDownloadFileComponent } from '../../../../components/modal-download-file/modal-download-file'
import { StatusComponent } from '../../../../components/status/status'
import { DatePickerRangeComponent } from '../../../../components/date-picker-range/date-picker-range'
import { FormControl, FormGroup } from '@angular/forms'
import { SearchFilterComponent } from './search-filter/search-filter'
import { showCustomerName } from '../../../../helpers/customer'

@Component({
  selector: 'app-product-seller-list',
  standalone: true,
  imports: [
    ...imports,
    MatPaginator,
    StatusComponent,
    DatePickerRangeComponent,
    SearchFilterComponent,
  ],
  templateUrl: './product-seller-list.html',
  styleUrls: ['./product-seller-list.scss'],
})
export class ProductSellerListComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly Moment = Moment
  readonly showCustomerName = showCustomerName

  @Input() product: any

  readonly paginationProductSellerList = new Pagination({
    sortBy: 'desc',
  })
  productSellerList: any[] = []

  enableExport = false
  form = new FormGroup({
    start_date: new FormControl(''),
    end_date: new FormControl(''),
  })

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public productService: ProductService,
    public filterTablePrroductSeller: FilterTablePrroductSeller
  ) {}

  ngOnInit(): void {
    this.getProductSellerList()
  }

  getFilter() {
    const dataFilter = this.filterTablePrroductSeller.getData()

    const value = this.form.getRawValue()
    if (value.start_date) {
      dataFilter.filter.start_date = convertDateToApi(value.start_date)
    }
    if (value.end_date) {
      dataFilter.filter.end_date = convertDateToApi(value.end_date, 'day')
    }

    return dataFilter
  }

  getProductSellerList() {
    const pagination = this.paginationProductSellerList.get()
    const dataFilter = this.getFilter()

    const payload = {
      ...pagination,
      ...dataFilter,
    }

    this.loading.start()
    this.productService.getProductOrderList(this.product.id, payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.productSellerList = res.data.records.map((d: any) => {
            if ([ORDER_ID.CREATE, ORDER_ID.VERIFY].includes(d.status.id)) {
              d.statusColorId = 1
              d.statusColorName = 'ยังไม่ชำระเงิน'
            } else if ([ORDER_ID.PAID].includes(d.status.id)) {
              d.statusColorId = 2
              d.statusColorName = 'รอพิมพ์ที่อยู่'
            } else if ([ORDER_ID.PRINTED].includes(d.status.id)) {
              d.statusColorId = 2
              d.statusColorName = 'รอเลขพัสดุ'
            } else if ([ORDER_ID.SHIPMENT].includes(d.status.id)) {
              d.statusColorId = 3
              d.statusColorName = 'ส่งสินค้าแล้ว'
            } else if ([ORDER_ID.CANCEL].includes(d.status.id)) {
              d.statusColorId = 1
              d.statusColorName = 'ยกเลิก'
            } else {
              d.statusColorId = 0
              d.statusColorName = ''
            }
            return d
          })
          this.paginationProductSellerList.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  changeDate() {
    this.paginationProductSellerList.reset()
    this.getProductSellerList()

    const foemValue = this.form.getRawValue()
    if (foemValue.start_date && foemValue.end_date) {
      const dateStart = Moment(foemValue.start_date)
      const dateEnd = Moment(foemValue.end_date)
      const months = dateEnd.diff(dateStart, 'months')
      this.enableExport = months == 0
    } else {
      this.enableExport = false
    }
  }

  openModalExportProductSeller(title: string, detail: any): void {
    const dialogRef = this.dialog.open(ModalDownloadFileComponent, {
      data: {
        title,
        detail,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exportProductSeller()
      }
    })
  }

  exportProductSeller() {
    const pagination = this.paginationProductSellerList.get()
    const dataFilter = this.getFilter()
    const payload = {
      sort_by: pagination.sort_by,
      sort_name: pagination.sort_name,
      ...dataFilter,
    }

    this.loading.start()
    this.productService.exportProductOrder(this.product.id, payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          DownloadFileBlob(res.body, `product-seller-lists.xlsx`)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }
}
