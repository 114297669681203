<div class="page-content-inner mb-3">
  <div class="top-content-wrapper align-items-center flex-wrap mb-3">
    <div class="top-content-left align-items-start">
      <div class="title-blue mb-sm-3 title-filter">รายการขายของ</div>
      <app-search-filter-product-seller
        placeholder="ค้นหาออเดอร์"
        (onConfirm)="paginationProductSellerList.reset();getProductSellerList()"></app-search-filter-product-seller>
    </div>
    <div class="top-content-right">
      <div class="font-14">วันที่สั่งซื้อ</div>
      <app-date-picker-range
        [controlStr]="form.controls['start_date']"
        [controlEnd]="form.controls['end_date']"
        [hideError]="true"
        (onChange)="changeDate()"></app-date-picker-range>

      @if (profile.permissions.checkAccess([APP_PERMISSION.PRODUCT], [ACTION_ACCESS.VIEW])) {
      <button
        matRipple
        [disabled]="!enableExport"
        (click)="openModalExportProductSeller('ดาวน์โหลดไฟล์รายการขายของ', { message: 'คุณต้องการดาวน์โหลดไฟล์รายการขายของที่เลือกใช่หรือไม่' })"
        class="btn btn-outline-secondary min-w-120px">
        <span class="icon material-symbols-outlined fill">download_2</span>
        ส่งออกไฟล์
      </button>
      }
    </div>
  </div>

  <div class="table-responsive-detail">
    <table class="table table-fixed-column table-detail">
      <thead>
        <tr>
          <th width="150">เลขที่ออเดอร์</th>
          <th width="280">ชื่อสินค้า</th>
          <th width="280">ชื่อลูกค้า</th>
          <th width="170">วันที่สั่งซื้อ</th>
          <th width="150" class="text-center">จำนวน</th>
          <th width="178" class="text-center">สถานะ</th>
        </tr>
      </thead>
      <tbody>
        @for (item of productSellerList; track $index) {
        <tr>
          <td>{{ item.order_no }}</td>
          <td>
            <app-text-tooltip> {{ item.product_name }} </app-text-tooltip>
          </td>
          <td>{{ showCustomerName(item.customer_name, item.customer_last_name) }}</td>
          <td>{{ Moment(item.order_date).format('DD-MM-YYYY, HH:mm') }}</td>
          <td class="text-center">{{ formatPriceAutoDecimal(item.quantity) }}</td>
          <td>
            <div class="d-flex justify-content-center">
              <app-status [status]="item.statusColorId"> {{ item.statusColorName }} </app-status>
            </div>
          </td>
        </tr>
        }
      </tbody>
    </table>
    @if(!productSellerList.length) {
    <div class="data-not-found-wrapper">ไม่พบข้อมูล</div>
    }
  </div>

  <!-- Pagination -->
  @if (productSellerList.length) {
  <div class="pagination-wrapper">
    <mat-paginator
      [pageIndex]="paginationProductSellerList.data.pageIndex"
      [length]="paginationProductSellerList.data.length"
      [pageSize]="paginationProductSellerList.data.pageSize"
      [pageSizeOptions]="paginationProductSellerList.data.pageSizeOptions"
      (page)="paginationProductSellerList.setFromPaginator($event);getProductSellerList()"
      aria-label="Select page">
    </mat-paginator>
  </div>
  }
</div>
