<div class="page-content-inner">
  <div class="top-content-wrapper align-items-center flex-wrap mb-3">
    <div class="top-content-left"><div class="title-blue">ประวัติการปรับสต๊อก</div></div>
    <div class="top-content-right">
      @if (profile.permissions.checkAccess([APP_PERMISSION.PRODUCT], [ACTION_ACCESS.VIEW])) {
      <button
        matRipple
        (click)="openModalExportStrockLog('ดาวน์โหลดไฟล์สินค้า', { message: 'คุณต้องการดาวน์โหลดไฟล์ประวัติการปรับสต๊อกที่เลือกใช่หรือไม่' })"
        class="btn btn-outline-secondary min-w-120px">
        <span class="icon material-symbols-outlined fill">download_2</span>
        ส่งออกไฟล์
      </button>
      }
    </div>
  </div>

  <div class="table-responsive-detail">
    <table class="table table-fixed-column table-detail">
      <thead>
        <tr>
          <th width="250">ชื่อสินค้า</th>
          <th width="100" class="text-center">เพิ่ม/ลด</th>
          <th width="100" class="text-center">จำนวน</th>
          <th width="180" class="text-end">ต้นทุนต่อหน่วย (บาท)</th>
          <th width="150">ผู้ขาย</th>
          <th width="170">วันที่ปรับ</th>
          <th width="218">ปรับโดย</th>
        </tr>
      </thead>
      <tbody>
        @for (item of productStockLogList; track $index) {
        <tr>
          <td>
            <app-text-tooltip>  {{ item.product_detail?.name || item.product.name }} </app-text-tooltip>
          </td>
          <td>
            <div class="d-flex align-items-center justify-content-center">
              @if (item.is_up) {
              <span class="icon icon-20 material-symbols-outlined fill color--green-600 me-1"
                >arrow_upward</span
              >
              เพิ่ม } @else {
              <span class="icon icon-20 material-symbols-outlined fill color--red-600 me-1"
                >arrow_downward</span
              >
              ลด }
            </div>
          </td>
          <td class="text-center">{{ formatPriceAutoDecimal(item.stock) }}</td>
          <td class="text-end">{{ formatPrice(item.cost_price) }}</td>
          <td>{{ item.supplier?.name || '-' }}</td>
          <td>{{ Moment(item.updated_at).format('DD-MM-YYYY, HH:mm') }}</td>
          <td>{{ item.updated_by ? item.updated_by.full_name : '' }}</td>
        </tr>
        }
      </tbody>
    </table>
    @if(!productStockLogList.length) {
    <div class="data-not-found-wrapper">ไม่พบข้อมูล</div>
    }
  </div>

  <!-- Pagination -->
  @if (productStockLogList.length) {
  <div class="pagination-wrapper">
    <mat-paginator
      [pageIndex]="paginationStockLog.data.pageIndex"
      [length]="paginationStockLog.data.length"
      [pageSize]="paginationStockLog.data.pageSize"
      [pageSizeOptions]="paginationStockLog.data.pageSizeOptions"
      (page)="paginationStockLog.setFromPaginator($event);getProductStockLogList()"
      aria-label="Select page">
    </mat-paginator>
  </div>
  }
</div>
