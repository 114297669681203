import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  Validators,
} from '@angular/forms'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  formatPrice,
  Pagination,
  formatPriceAutoDecimal,
  productImage,
  productKey,
} from '../../helpers'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import {
  CompanyProfileService,
  CaratService,
  ProductService,
  MasterService,
  ProductCategoryService,
} from '../../services'
import { CustomSnackBar } from '../snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../globals'
import { forkJoin } from 'rxjs'
import { MatRadioModule } from '@angular/material/radio'
import { MatRipple } from '@angular/material/core'
import { MatIconModule } from '@angular/material/icon'
import { MatTabsModule } from '@angular/material/tabs'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { imports } from '../../imports'
import { InputComponent } from '../input/input'
import { TextareaComponent } from '../text-area/text-area'
import { SelectComponent } from '../select/select'
import { InputSearchComponent } from '../input-search/input-search'
import { MatPaginator } from '@angular/material/paginator'
import { InputNumberComponent } from '../input-number/input-number'
import { CheckboxTableComponent } from '../checkbox-table/checkbox-table.component'
import { MatCheckbox } from '@angular/material/checkbox'

@Component({
  selector: 'app-modal-select-product-multiple',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    MatRadioModule,
    SelectComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatIconModule,
    MatRipple,
    InputSearchComponent,
    InputNumberComponent,
    MatPaginator,
    CheckboxTableComponent,
    MatCheckbox,
  ],
  templateUrl: './modal-select-product-multiple.html',
  styleUrls: ['./modal-select-product-multiple.scss'],
})
export class ModalSelectProductMultipleComponent implements OnInit {
  // @Input() form: any = new FormGroup('')
  // @Input() sectionIndex: number = 0
  // @Input() showButtonDelete: boolean = false
  // @Output() deleteSection = new EventEmitter()
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly pagination = new Pagination({
    sortName: 'code',
  })

  // mobile = window.matchMedia('(max-width: 1000px)')

  isEdit: boolean = false
  isView: boolean = false
  rerender = false
  pageNumber = new FormControl(1)

  formSearch = new FormGroup({
    search_name: new FormControl(),
    category: new FormControl(),
    sub_category: new FormControl(),
  })
  form = new FormGroup({
    product_id: new FormControl(),
    product_name: new FormControl(),
  })

  productSelect: any[] = []
  listProduct: any[] = []

  list = {
    category: <any[]>[],
  }

  get subCategories() {
    return (
      this.list.category.find((d: any) => d.id == this.formSearch.controls.category.value)
        ?.product_sub_categories || []
    )
  }

  get productIds() {
    if (this.data.noGroup) {
      return this.productSelect
    }
    return this.productSelect.reduce((productIds: any[], item: any) => {
      if (!productIds.includes(item.product_id)) {
        productIds.push(item.product_id)
      }

      return productIds
    }, [])
  }

  constructor(
    public dialogRef: MatDialogRef<ModalSelectProductMultipleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public productCategoryService: ProductCategoryService,
    public productService: ProductService
  ) {}

  ngOnInit(): void {
    this.initData()
    this.getProductList()
  }

  initData() {
    this.formSearch.controls.category.setValue(this.data?.category_id)
    const oldProducts = this.data.product_list || []
    for (const oldProduct of oldProducts) {
      this.productSelect.push({
        key: productKey(oldProduct.product_id, oldProduct.product_detail_id),
        ...oldProduct,
      })
    }

    const apis = [
      this.productCategoryService.getProductCategoryList({
        is_all: true,
      }),
    ]
    this.loading.start()
    forkJoin(apis).subscribe(([resProductCategoryList]) => {
      if (!resProductCategoryList.is_error) {
        this.list.category = resProductCategoryList.data
      } else {
        this.customSnackBar.fail()
      }
      this.loading.stop()
    })
  }

  // clear() {
  //   this.productSelect = []
  // }

  changeCatecory() {
    this.pagination.reset()
    this.formSearch.controls['sub_category'].reset()
    this.getProductList()
  }

  changeSubCatecory() {
    this.getProductList()
  }

  onSearch() {
    this.pagination.reset()
    this.getProductList()
  }

  onChangePage(reset = false) {
    if (this.pageNumber.value) {
      const page = this.pageNumber.value - 1
      if (this.pagination.data.pageIndex != page) {
        this.pagination.data.pageIndex = page
        this.getProductList()
      }
    } else if (reset) {
      this.pageNumber.setValue(this.pagination.data.pageIndex + 1)
      this.rerender = true
      setTimeout(() => {
        this.rerender = false
      })
    }
  }

  getProductList() {
    let exclude_details = undefined
    if (this.data?.filter_product) {
      exclude_details = this.data.filter_product.map((d: any) => {
        return `${d.product_id}:${d.product_detail_id || 0}`
      })
    }
    const pagination = this.pagination.get()
    const foemValue = this.formSearch.getRawValue()
    const payload: any = {
      ...pagination,
      search: foemValue.search_name || undefined,
      filter: {
        // is_actives: [true],
        // is_stock: false,
        exclude_details: exclude_details,
        is_not_flash_sale: this.data?.filter_not_flash_sale || undefined,
        is_not_product_discount: this.data?.filter_not_product_discount || undefined,
      },
    }

    if (this.data.except_product_ids && this.data.except_product_ids[0]) {
      payload.filter.except_product_ids = this.data.except_product_ids
    }

    if (foemValue.category) {
      payload.filter.category_ids = [foemValue.category]
    }

    if (foemValue.sub_category) {
      payload.filter.sub_category_ids = [foemValue.sub_category]
    }

    if (this.data.is_size_guide === false) {
      payload.filter.is_size_guide = false
    }

    if (this.data.is_size_measurement === false) {
      payload.filter.is_size_measurement = false
    }

    if (this.data.color) {
      payload.color = this.data.color
    }

    // console.log(payload)
    // return

    this.loading.start()
    this.productService.getProductForOrderList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.listProduct = res.data.records.map((product: any) => {
            const key = productKey(product.id, product.product_detail_id)

            const file = productImage(product)
            const newProduct = {
              key,
              product_id: product.id,
              file,
              ...product,
              product: product.product_detail_id ? null : product,
              product_detail: product.product_detail_id ? product : null,
              image: file,
            }

            return newProduct
          })

          this.pagination.setFromResponse(res.data)
          this.pageNumber.setValue(res.data.page)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  checkItem(key: any) {
    return this.productSelect.find((d: any) => d.key == key)
  }

  onCheck(item: any) {
    // console.log('item', item)

    const data = this.checkItem(item.key)
    if (data) {
      this.productSelect.splice(this.productSelect.indexOf(data), 1)
    } else {
      this.productSelect.push(item)
    }
  }

  isProductSelect(item: any) {
    return this.productIds.includes(item.product_id)
  }

  log(log: any) {
    console.log('log', log)
  }

  onClose(): void {
    this.dialogRef.close(false)
  }

  onConfirm(): void {
    const datas: any[] = [...this.productSelect]
    this.dialogRef.close(datas)
  }
}
